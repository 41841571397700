import React from 'react'
import { AllAnimationsCombined } from '../../../components/Animation/Animation'
import { Footer } from '../../../components/Footer'
import { Menu } from '../../../components/Menu/Menu'
import SEO from '../../../components/Seo/Seo'
import '../../../styles/style.scss'
import { Contact as ContactContent } from './ContactContent'

export const Contact = (props) => {
  return (
    <>
      <SEO title="Zapisz się" />
      <AllAnimationsCombined />
      <Menu data={props.data} location={props.location} />
      <main>
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div className="container">
              <ContactContent data={props.data} withoutHeader />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}