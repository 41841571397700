import { Contact } from '../containers/Landing/Contact/Contact';

export default Contact

export const query = graphql`
query MyQueryContact($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  datoCmsContactPage(locale: {eq: $language}) {
    header
    informationBox
    subtitle
    buttonCta
  }
  allDatoCmsPageLanguage( filter: {locale: {eq: $language}}) {
    edges {
      node {
        name
        flag
        abbreviation
        flag
      }
    }
  }
  datoCmsLanding(locale: {eq: $language}) {
      menu {
        label
        anchor
      }
      headline
      subtitle
      topImage {
        blurhash
        alt
        url(imgixParams: {q: 70, h: "720", w: "332"})
      }
      whyUnderline {
        id
        copy
        headline
        iconBackgroundColor {
          hex
        }
        icon {
          url
          alt
        }
      }
      contactForm {
        label
        name
        placeholder
        inputType
      }
      checkboxTermsConditions
    }
  }
`;

