/* global fbq */
import { navigate } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'
import { AllAnimationsCombined } from '../../../components/Animation/Animation'

const FORM_URL = "https://logs.getunderline.io/tracklog"

const Error = () => {
  return (
    <p className="has-text-left form-error">Wpisz swój email</p>
  )
}

const SingleField = (props) => {
  const { inputProps, isErrorVisible } = props
  return (
    <div className="field" style={{ marginBottom: 24 }}>
      <label className="label has-text-left " style={{ fontSize: "0.87rem", lineHeight: '170%' }}>{inputProps.label}</label>
      <div className="control">
        {inputProps.inputType === "input" &&
          <input
            className="input"
            type="text"
            // required
            placeholder={inputProps.placeholder}
            name={inputProps.name}
            onChange={e => inputProps.handleChangeField(e.target.value)}
            value={inputProps.value}
          />
        }

        {inputProps.inputType === "textarea" &&
          <textarea
            className="textarea"
            placeholder={inputProps.placeholder}
            style={{ padding: "15px 16px" }}
            name={inputProps.name}
            onChange={e => inputProps.handleChangeField(e.target.value)}
            value={inputProps.value}
          />
        }

        {isErrorVisible && <Error />}

      </div>
    </div>
  )
}

export const Contact = (props) => {
  const i18nHook = useI18next()
  const contactFormFields = props.data.datoCmsLanding.contactForm
  const datoCmsContactPage = props.data.datoCmsContactPage
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [formData, setFormData] = useState({
    data: "[EMAIL WYSLANY AUTOMATYCZNIE] Nowe zapisanie się do beta z landingu"
  })
  const handleChangeField = (fieldName) => (fieldValue) => {
    setFormData({
      ...formData,
      user_mail: fieldValue,
      send_underline_access: true,
      [fieldName]: fieldValue,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!formData.email) {
      setIsErrorVisible(true)
    } else {
      setIsErrorVisible(false)
      setIsLoading(true)
      fetch(FORM_URL, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json"
        }
      }).then(res => {
        if (res.status === 200 || res.status === 201) {
          setFormData({
            email: ''
          })
          setIsLoading(false)
          fbq('track', 'Lead');
          i18nHook.navigate("/thank-you")
        }
      })
    }
  }
  return (
    <section className="hero is-halfheight" style={{ position: 'relative', }}>
      <AllAnimationsCombined contact />
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered">


              <h1 className="title is-2 has-text-weight-bold" style={{ lineHeight: '150%', }}>
                {datoCmsContactPage.header}
              </h1>
              <div className="is-flex is-justify-content-center	">
                <p style={{ maxWidth: 568, lineHeight: '160%', fontSize: 16 }}
                  dangerouslySetInnerHTML={{ __html: datoCmsContactPage.subtitle }} />
              </div>
              <div className="is-flex is-justify-content-center" style={{ marginTop: 16 }}>
                <article className="message is-info" style={{ maxWidth: 568, lineHeight: '160%' }}>
                  <div
                    className="message-body"
                    style={{ borderWidth: 0, background: '#EAF3FF', fontSize: 13, borderRadius: 8, color: '#6297FF', fontWeight: 600, textAlign: 'left' }}
                    dangerouslySetInnerHTML={{ __html: datoCmsContactPage.informationBox }} />
                </article>
              </div>
            </div>
          </div>

          <div className="columns is-vcentered" style={{ marginTop: 8 }}>
            <div className="column is-half is-offset-one-quarter has-text-centered">
              <form onSubmit={handleSubmit}>
                {contactFormFields.map(item => {
                  return (
                    <SingleField
                      key={item.name}
                      isErrorVisible={isErrorVisible}
                      error="Wpisz swój adres email"
                      inputProps={{
                        name: item.name,
                        label: item.label,
                        placeholder: item.placeholder,
                        inputType: item.inputType,
                        handleChangeField: handleChangeField(item.name),
                        value: formData[item.name]
                      }} />
                  )
                })}
                <div className="mt-5">
                  <button type="submit" className={`button is-primary has-text-weight-semibold is-3 ${isLoading && "is-loading"}`}>
                    {datoCmsContactPage.buttonCta}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}